import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { createPortal } from "react-dom";
import SearchPopper from "../../../../components/SearchPopper";
import Icon from "../../../../components/UI/Icon";

export type SelectableUser = {
  ID: string;
  name: string;
};

type Props = {
  users: SelectableUser[];
  selectedUser: SelectableUser | undefined;
  onChange: (user: SelectableUser) => void;
};

const SearchableMenuList = ({
  users,
  onChange,
}: Omit<Props, "selectedUser">) => {
  const { body } = document;
  return createPortal(
    <chakra-scope>
      <MenuList maxH={380} p={0} overflowY="auto" zIndex={10000}>
        <SearchPopper data={users || []} placeholder="Search Users">
          {({ data, onClose }) => {
            const userItems = data?.map((user) => (
              <MenuItem
                id={user.ID}
                key={user.ID}
                onClick={() => {
                  onClose();
                  onChange(user);
                }}>
                {user.name}
              </MenuItem>
            ));

            return [...userItems];
          }}
        </SearchPopper>
      </MenuList>
    </chakra-scope>,
    body,
  );
};

const UpdateUserSelect = ({ selectedUser, users, onChange }: Props) => {
  return (
    <Menu preventOverflow>
      <MenuButton
        size="sm"
        rightIcon={<Icon name="ChevronDown" />}
        as={Button}
        variant="outline"
        width="max-content">
        {selectedUser ? selectedUser.name : "Select User"}
      </MenuButton>
      <SearchableMenuList users={users} onChange={onChange} />
    </Menu>
  );
};

export default UpdateUserSelect;
