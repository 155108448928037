import {
  Button,
  chakra,
  Checkbox,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  UseDisclosureReturn,
  VStack
} from "@chakra-ui/react";
import { isClerkAPIResponseError } from "@clerk/clerk-js";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ShortTextField from "../../../../components/chakra/form/formComponents/ShortTextField";
import { useAuthPasswords } from "../../../../utilities/useAuth";
import useToast from "../../../../utilities/useToast";

type Props = {
  modalState: UseDisclosureReturn;
  onRefresh: () => void;
  type: "initial" | "update";
};

const schema = (type: "initial" | "update") =>
  z
    .object({
      newPassword: z
        .string()
        .min(8, "New password must be at least 8 characters"),
      confirmPassword: z.string(),
      currentPassword: type === "initial" ? z.string().optional() : z.string(),
      signOutOfOtherSessions: z.boolean(),
    })
    .superRefine(({ newPassword, confirmPassword }, ctx) => {
      if (newPassword !== confirmPassword) {
        ctx.addIssue({
          message: `Passwords must match`,
          code: "custom",
          path: ["confirmPassword"],
        });
      }
    });

export default function ChangePasswordModal({
  modalState,
  onRefresh,
  type,
}: Props) {
  const { displayToast } = useToast();
  const auth = useAuthPasswords();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema(type)),
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
      currentPassword: "",
      signOutOfOtherSessions: true,
    },
  });

  const onSubmit = async () => {
    if (!auth) return;
    const { newPassword, currentPassword, signOutOfOtherSessions } =
      form.getValues();
    if (type === "update" && currentPassword === newPassword) {
      form.setError("newPassword", {
        message: "New and old passwords are identical",
      });
      return;
    }
    try {
      if (type === "initial") {
        await auth.updatePassword({
          newPassword,
          signOutOfOtherSessions,
        });
      } else {
        await auth.updatePassword({
          newPassword,
          currentPassword,
          signOutOfOtherSessions,
        });
      }
      displayToast({
        status: "success",
        title: "Password updated",
      });
      onRefresh();
      modalState.onClose();
    } catch (error) {
      if (isClerkAPIResponseError(error)) {
        displayToast({
          status: "error",
          title: "Failed to change password",
          description: error.errors[0]?.message || "Something went wrong",
        });
      } else {
        displayToast({
          status: "error",
          title: "Failed to change password",
          description: "Something went wrong",
        });
      }
      onRefresh();
    }
  };

  const isBusy = false;
  return (
    <Modal
      onCloseComplete={form.reset}
      closeOnEsc={!isBusy}
      closeOnOverlayClick={!isBusy}
      isOpen={isBusy || modalState.isOpen}
      onClose={modalState.onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <FormProvider {...form}>
            <chakra.form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalHeader>
                {type === "initial" ? "Set" : "Change"} Password
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="full" align="start" spacing="1">
                  {type === "update" && (
                    <ShortTextField
                      fieldProps={{
                        label: "Current password",
                        isRequired: true,
                      }}
                      {...form.register("currentPassword")}
                      inputPropsOverride={{ type: "password" }}
                    />
                  )}
                  <ShortTextField
                    fieldProps={{ label: "New password", isRequired: true }}
                    {...form.register("newPassword")}
                    inputPropsOverride={{ type: "password" }}
                  />
                  <ShortTextField
                    fieldProps={{
                      label: "Confirm new password",
                      isRequired: true,
                    }}
                    {...form.register("confirmPassword")}
                    inputPropsOverride={{ type: "password" }}
                  />
                  <FormControl mb="2">
                    <Checkbox
                      {...form.register("signOutOfOtherSessions")}
                      mb="0">
                      Sign out of all other devices?
                    </Checkbox>
                    {type === "update" && (
                      <FormHelperText mt="0">
                        It is recomended to sign out of other devices that may
                        have used your old password.
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Heading size="xs">Tips for a good password</Heading>
                  <UnorderedList stylePosition="inside">
                    <ListItem>Use a series of words strung together</ListItem>
                    <ListItem>Keep it at least 8 characters long</ListItem>
                    <ListItem>Use a password manager</ListItem>
                    <ListItem>Don't re-use or recycle old passwords</ListItem>
                    <ListItem>Don't use all numbers or all letters</ListItem>
                    <ListItem>Don't use single words</ListItem>
                  </UnorderedList>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button
                    onClick={modalState.onClose}
                    variant="solid"
                    colorScheme="gray">
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!form.formState.isValid}
                    loadingText="Updating Password"
                    isLoading={form.formState.isSubmitting}
                    type="submit">
                    {type === "initial" ? "Set" : "Change"} Password
                  </Button>
                </HStack>
              </ModalFooter>
            </chakra.form>
          </FormProvider>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
