/**
 * Export of a bunch of ReactIcons for common use cases (like Registers, Requirements ect.)
 */

import {
  RiAddLine,
  RiAlertLine,
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiBook3Line,
  RiBookmark3Fill,
  RiClipboardLine,
  RiDeleteBin7Line,
  RiDownload2Line,
  RiEditBoxLine,
  RiEditLine,
  RiErrorWarningLine,
  RiFolderInfoLine,
  RiGridLine,
  RiHome8Line,
  RiInformationLine,
  RiLayoutColumnLine,
  RiMore2Fill,
  RiNotification3Line,
  RiPagesLine,
  RiPencilLine,
  RiRefreshLine,
  RiSafe2Line,
  RiStackLine,
  RiTableLine,
} from "react-icons/ri";

// Types
export const HomeIcon = RiHome8Line;
export const RequirementIcon = RiPagesLine;
export const RegisterIcon = RiStackLine;
export const ActionIcon = RiEditBoxLine;
export const IssueIcon = RiFolderInfoLine;
export const RiskIcon = RiAlertLine;
export const ManualIcon = RiBook3Line;
export const ReportIcon = RiTableLine;

export const TemplateManagementIcon = RiSafe2Line;

export const EditIcon = RiEditLine;
export const EditItemIcon = RiPencilLine;
export const DeleteIcon = RiDeleteBin7Line;
export const ExportIcon = RiDownload2Line;
export const AddIcon = RiAddLine;

export const DensityIcon = RiGridLine;

export const ColumnsIcon = RiLayoutColumnLine;

export const DropdownIcon = RiArrowDownSLine;

export const MoreMenuIcon = RiMore2Fill;

export const NotificationIcon = RiNotification3Line;

export const RefreshIcon = RiRefreshLine;

export const CopyToClipboardIcon = RiClipboardLine;

export const LeftIcon = RiArrowLeftSLine;
export const RightIcon = RiArrowRightSLine;

export const InfoIcon = RiInformationLine;
export const WarningIcon = RiErrorWarningLine;

// Colours
export const PositiveColor = "#87DC2C";
export const NeutralColor = "#2EB6EF"; // needs to be updated to "Warning" and an orange colour
export const NegativeColor = "#E22201";
export const ClosedColor = "#E3E7EC";

export const UpcomingColor = "#B3E97A";
export const DueColor = "#FFAB09";
export const OverdueColor = "#FC3E1B";
export const OpenRespondedColor = "#008BC0";
export const ClosedCompletedColor = "#65AD15";
export const ClosedMissedColor = "#B00D00";
