import {
  Button,
  ButtonGroup,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useAuthPasswords } from "../../../../utilities/useAuth";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ShortTextField from "../../../../components/chakra/form/formComponents/ShortTextField";
import useToast from "../../../../utilities/useToast";
import { isClerkAPIResponseError } from "@clerk/clerk-js";

const schema = z.object({
  currentPassword: z.string(),
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function ConfirmDeletePasswordModal({ isOpen, onClose }: Props) {
  const auth = useAuthPasswords();
  const { displayToast } = useToast();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      currentPassword: "",
    },
  });

  const initialFocusRef = useRef(null);
  const onSubmit = async () => {
    const { currentPassword } = form.getValues();
    if (!auth) return;
    if (!currentPassword) return;
    try {
      await auth.removePassword({
        currentPassword,
      });
      displayToast({
        status: "success",
        title: "Password removed",
      });
      onClose();
    } catch (error) {
      if (isClerkAPIResponseError(error)) {
        displayToast({
          status: "error",
          title: "Failed to remove password",
          description: error.errors[0]?.message || "Something went wrong",
        });
      } else {
        displayToast({
          status: "error",
          title: "Failed to remove password",
          description: "Something went wrong",
        });
      }
    }
  };
  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <FormProvider {...form}>
            <chakra.form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalHeader>Remove password?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="100%" align="start">
                <Text>
                  You will no longer be able to log in with your password. You
                  can still log in with connected {"'"}Single Sign-On{"'"}{" "}
                  providers, or via a magic link.
                </Text>
                <Text>You can always set a new password later.</Text>
                <ShortTextField
                  fieldProps={{
                    label: "Current password",
                    isRequired: true,
                  }}
                  {...form.register("currentPassword")}
                  inputPropsOverride={{ type: "password" }}
                />
                </VStack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    ref={initialFocusRef}
                    onClick={onClose}
                    colorScheme="gray">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={!form.formState.isValid}
                    colorScheme="red"
                    isLoading={form.formState.isSubmitting || auth === null}>
                    Remove password
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </chakra.form>
          </FormProvider>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
