import React from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { formatDateTime } from "../../utilities/formatDate";
import * as NoteType from "../../shared/constants/noteTypes";
import * as CoreFields from "../../shared/constants/coreFields";
import {
  ReportColumn,
  GeneratedReportRow,
  GeneratedReportColumn,
  GeneratedReportValue,
  GroupedGeneratedReportResult,
} from "../../shared/definitions/reports";
import { ReportPageData } from "./Report.d";
import { arraysAreEqual } from "../../utilities/arrays";

const formatListValue = (listValue: any) => {
  if (listValue.ListValueVARCHAR) {
    return listValue.ListValueVARCHAR;
  }
  if (listValue.DocumentID) {
    return listValue.DocumentName;
  }
  if (listValue.ListValueDATETIME) {
    return moment(listValue.ListValueDATETIME).format("DD-MMM-YYYY");
  }
  if (listValue.ListValueINT) {
    return listValue.ListValueINT;
  }
  return "";
};

// this will get the name of the list value instead of the id given
const getTextValueOfList = (fieldID: string, listID: string, fields: any[]) => {
  const listField = fields.find(
    (field: any) => field.CustomFieldID === fieldID,
  );
  if (listField) {
    const listValue = listField.ListValues.find(
      (value: any) => value.ListValueID === listID,
    );
    if (listValue) {
      return formatListValue(listValue);
    }
  }
  return "";
};

const displayFilters = (filters: any, columns: any, fields: any) =>
  filters.map((filter: any, index: number) => {
    let conditionText = ``;
    const column = columns.find(
      (col: any) => col.ReportColumnID === filter.ReportColumnID,
    );
    if (column) {
      const value = findNotNull([
        filter.ReportFilterValueINT,
        filter.ReportFilterValueURL,
        filter.ReportFilterValueVARCHAR,
      ]);
      conditionText += `${
        index === 0 ? "" : filter.ReportFilterJoiningOperator
      } ${column.Name} ${filter.ReportFilterValueOperator} ${
        filter.ReportFilterValueOperator === "exists"
          ? ""
          : filter.ListValueID
          ? getTextValueOfList(filter.FieldID, filter.ListValueID, fields)
          : filter.ReportFilterValueDATETIME &&
            !filter.ReportFilterValueOperator.includes("relative")
          ? moment
              .utc(filter.ReportFilterValueDATETIME)
              .local()
              .format("DD-MMM-YYYY")
          : column.Name === "Time Note Billable"
          ? value === 1
            ? "Billable"
            : "Nonbillable"
          : value
      } `;
    }
    return conditionText;
  });

const displayReport = (generatedData: GroupedGeneratedReportResult) => (
  <>
    {generatedData.tables.map((table) => (
      <React.Fragment key={table.groupValue}>
        {table.heading && table.heading !== "" ? (
          <h1>{table.heading}</h1>
        ) : null}
        <Table
          className="report-table"
          bordered
          striped
          responsive
          title={table.groupValue}>
          <thead>
            {table.headerRows.map((row, index) => (
              <tr key={index}>
                {row.columns.map((col) => (
                  <th colSpan={col.span} key={col.reportColumnID}>
                    {col.text}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.bodyRows.map((row, index) => (
              <tr
                key={`key-${row.recordID}-${row.parentID}-${row.grandParentID}-${row.childID}-${row.actionID}-${index}`}>
                {row.columns.map((col) =>
                  formatTableDataCell(
                    col,
                    {
                      colSpan: col.span,
                      key: `${row.recordID}-${row.parentID}-${row.grandParentID}-${row.childID}-${row.actionID}-${col.columnID}-${index}`,
                    },
                    "display",
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    ))}
  </>
);

const formatTableDataCell = (
  col: GeneratedReportColumn,
  commonProps: any,
  type: string,
) => {
  const cellValue =
    col.fieldDataType === "LONGVARCHAR" ? undefined : formatCellValue(col);
  const innerHTML =
    col.fieldDataType === "LONGVARCHAR" ? formatInnerHTML(col) : undefined;
  // two different types available that need the passing of background colors to be done differently

  if (type === "display") {
    const cellCSS = formatCellCSS(col);
    return col.fieldDataType === "LONGVARCHAR" ? (
      <td {...commonProps} dangerouslySetInnerHTML={{ __html: innerHTML }} />
    ) : (
      <td {...commonProps} style={{ ...cellCSS }}>
        {cellValue}
      </td>
    );
  }
  if (type === "print") {
    return getReturnValueForCell(
      col,
      col.value,
      cellValue,
      innerHTML,
      commonProps,
    );
  }
  // should not be here
  return <td {...commonProps}>{cellValue}</td>;
};

// to print the status and lifecycle colours - this will need to be modified as well

const getReturnValueForCell = (
  col: GeneratedReportColumn,
  value: GeneratedReportValue | GeneratedReportValue[],
  cellValue: any,
  innerHTML: any,
  commonProps: any,
) => {
  // if array, pass nothing
  if (Array.isArray(value)) {
    return "";
  }
  // if backend declares a colour, pass through colour
  if (value.colour) {
    return (
      <td
        {...commonProps}
        style={{ color: "#fff", textAlign: "center", verticalAlign: "middle" }}
        ref={(element) => {
          if (element && value.colour !== undefined) {
            element.style.setProperty(
              "background-color",
              value.colour,
              "important",
            );
          }
        }}>
        {cellValue}
      </td>
    );
  }

  // if not, need to check if a status (or lifecycle) value and pass colour assigned to value
  // status field
  if (
    [
      CoreFields.Action.Status,
      CoreFields.Issue.Status,
      CoreFields.Register.Status,
      CoreFields.Requirement.Status,
    ].includes(col.fieldID)
  ) {
    return (
      <td
        {...commonProps}
        style={{ color: "#fff", textAlign: "center", verticalAlign: "middle" }}
        ref={(element) => {
          if (element && getColorForStatusName(value.text) !== undefined) {
            element.style.setProperty(
              "background-color",
              getColorForStatusName(value.text),
              "important",
            );
          }
        }}>
        {cellValue}
      </td>
    );
  }

  // lifecycle field
  if (CoreFields.Action.InstanceLifecycle.includes(col.fieldID)) {
    return (
      <td
        {...commonProps}
        style={{ color: "#fff", textAlign: "center", verticalAlign: "middle" }}
        ref={(element) => {
          if (element && getColorForLifecycle(value.text) !== undefined) {
            element.style.setProperty(
              "background-color",
              getColorForLifecycle(value.text),
              "important",
            );
          }
        }}>
        {cellValue}
      </td>
    );
  }

  return col.fieldDataType === "LONGVARCHAR" ? (
    <td {...commonProps} dangerouslySetInnerHTML={{ __html: innerHTML }} />
  ) : (
    <td {...commonProps}>{cellValue}</td>
  );
};

// Terrible because report returns the status like "Positive" ect.

// a little janky since it's using hardcoded colours but it works??

// colours will eventually be moved over to new theme - colours located in commonicons
// and no longer hardcoded here

// if statement to assign colours of statues
const getColorForStatusName = (status: string) => {
  if (status === "Negative") {
    return "rgb(197, 48, 48)";
  }
  if (status === "Positive") {
    return "rgb(47, 133, 90)";
  }
  if (status === "Neutral") {
    return "rgb(43, 108, 176)";
  }
  if (status === "Draft") {
    return "secondary";
  }
  return "";
};

// if statement to assign colours to lifecycle
const getColorForLifecycle = (lifecycle: string) => {
  if (lifecycle === "Upcoming") {
    return "rgb(56, 178, 172)";
  }
  if (lifecycle === "Due") {
    return "rgb(49, 130, 206)";
  }
  if (lifecycle === "Overdue") {
    return "rgb(221, 107, 32)";
  }
  if (lifecycle === "Closed (Completed)") {
    return "rgb(44, 82, 130)";
  }
  if (lifecycle === "Closed (Missed)") {
    return "rgb(155, 44, 44)";
  }
  if (lifecycle === "Open Responded") {
    return "rgb(159, 122, 234)";
  }
  return "";
};

const formatCellCSS = (column: GeneratedReportColumn) => {
  const { value } = column;

  if (Array.isArray(value)) {
    return "";
  }

  // If backend declares a colour, use it
  if (value.colour) {
    return {
      backgroundColor: value.colour,
      color: "#ffffff",
      textAlign: "center",
      verticalAlign: "middle",
    };
  }

  // Otherwise, if we know we want to colour this field type, use it

  // If status field (positive/negative ect)
  if (
    [
      CoreFields.Action.Status,
      CoreFields.Issue.Status,
      CoreFields.Register.Status,
      CoreFields.Requirement.Status,
    ].includes(column.fieldID)
  ) {
    return {
      backgroundColor: getColorForStatusName(value.text),
      color: "#fff",
      textAlign: "center",
      verticalAlign: "middle",
    };
  }

  // if lifecycle field
  if (CoreFields.Action.InstanceLifecycle.includes(column.fieldID)) {
    return {
      backgroundColor: getColorForLifecycle(value.text),
      color: "#fff",
      textAlign: "center",
      verticalAlign: "middle",
    };
  }

  return "";
};

const formatCellValue = (col: GeneratedReportColumn) => {
  if (Array.isArray(col.value) && col.value.every((val) => val.link)) {
    return col.value.reduce(
      (acc: any, val, index) =>
        [
          ...acc,
          <a
            key={`link-${col.fieldID}-${val.id}`}
            href={val.link}
            target="_blank"
            rel="noreferrer">
            {val.text}
          </a>,
        ].concat(
          Array.isArray(col.value) && index < col.value.length! - 1
            ? [", "]
            : [],
        ),
      [],
    );
  }
  if (Array.isArray(col.value)) {
    return col.value.map((val) => val.text).join(", ");
  }
  if (col.value.link) {
    return (
      <a href={col.value.link} target="_blank" rel="noreferrer">
        {col.value.text}
      </a>
    );
  }
  if (
    col.fieldDataType === "DATETIME" &&
    col.fieldID === CoreFields.Action.InstanceDueDate
  ) {
    return col.value.text
      ? formatDateTime({ date: col.value.text, format: "Date" })
      : "";
  }
  if (
    col.fieldDataType === "DATETIME" &&
    col.fieldID === CoreFields.Action.FirstActionDate
  ) {
    return col.value.text
      ? moment
          .utc(col.value.text)
          .subtract(24, "hours")
          .local()
          .format("DD-MMM-YYYY")
      : "";
  }
  if (col.fieldDataType === "DATETIME") {
    return col.value.text
      ? moment.utc(col.value.text).local().format("DD-MMM-YYYY")
      : col.fieldID === CoreFields.Action.InstanceResponseDate
      ? "No response"
      : col.fieldID === CoreFields.Action.RiskAssessmentDate
      ? "No assessment"
      : "";
  }
  return col.value.text;
};

const formatInnerHTML = (col: GeneratedReportColumn): string => {
  if (
    col.noteTypeID === NoteType.Note &&
    !Array.isArray(col.value) &&
    col.value.notes
  ) {
    return col.value.notes.reduce(
      (final: any, current: any) =>
        (final += `<b>${current.userName} ${moment
          .utc(current.createTs)
          .local()
          .format("DD-MMM-YYYY HH:mm")}</b><br>${current.noteText}<br><br>`),
      "",
    );
  }
  if (
    col.noteTypeID === NoteType.TimeNote &&
    !Array.isArray(col.value) &&
    col.value.notes &&
    col.value.notes.length > 0
  ) {
    let finalString: string = "";
    let totalTime: number = 0;
    let totalTimeBillable: number = 0;
    col.value.notes.forEach((note: any) => {
      finalString += `<b>${note.userName} ${moment
        .utc(note.createTs)
        .local()
        .format("DD-MMM-YYYY HH:mm")}</b> ${note.noteTime}hrs ${
        note.noteTimeDate
          ? moment.utc(note.noteTimeDate).local().format("DD-MMM-YYYY")
          : ""
      } ${note.noteTimeBillable ? " (billable)" : ""}<br>${
        note.noteText
      }<br><br>`;
      if (note.noteTime) {
        totalTime += note.noteTime;
        if (note.noteTimeBillable) {
          totalTimeBillable += note.noteTime;
        }
      }
    });
    return `<b>Total:</b> ${totalTime} hours (${totalTimeBillable} billable hours)<br><br>${finalString}`;
  }
  return Array.isArray(col.value) ? col.valueText : col.value.text;
};

// needs to work with value of 0 for nonbillable
const findNotNull = (values: any[]) =>
  values.find(
    (value: any) => value !== null && value !== undefined && value !== "",
  );

const levelToTitle: { [key: string]: any } = {
  "-2": "Action",
  "-1": "Child",
  "0": "Subject",
  "1": "Parent",
  "2": "Grand Parent",
};

const formatColumnFieldsFromDB = (data: ReportPageData) => {
  if (data) {
    // set ID as required column field, default order by field
    const objectTypeGroupName: any = data.ObjectTypeName;
    const coreFieldKey: keyof typeof CoreFields = objectTypeGroupName;
    const idField = data.CoreFields.find(
      (cf: any) => cf.CoreFieldID === CoreFields[coreFieldKey].ID,
    );
    data.Columns =
      data.Columns.length > 0
        ? data.Columns.map((col: any) => ({
            ...col,
            value: `${col.Level}_${col.ID}`,
            label: `${
              col.Level === 0
                ? objectTypeGroupName
                : levelToTitle[col.Level.toString()]
            }: ${col.Name}`,
            isFixed: col.Level === 0 && col.ID === idField.CoreFieldID,
          }))
        : [
            {
              ID: idField.CoreFieldID,
              Name: `${objectTypeGroupName}: ${idField.CoreFieldLabel}`,
              Type: "Core",
              CustomFieldTypeColumnName: idField.CustomFieldTypeColumnName,
              Order: 0,
              Level: 0,
              isFixed: true,
              label: `${objectTypeGroupName}: ${idField.CoreFieldLabel}`,
              value: `0_${idField.CoreFieldID}`,
            },
          ];
    data.OrderBy =
      data.OrderBy.length > 0
        ? data.OrderBy.map((col: any) => ({
            ...col,
            value: `${col.Level}_${col.ID}`,
            label: `${
              col.Level === 0
                ? objectTypeGroupName
                : levelToTitle[col.Level.toString()]
            }: ${col.Name}`,
          }))
        : [
            {
              ID: idField.CoreFieldID,
              Name: `${objectTypeGroupName}: ${idField.CoreFieldLabel}`,
              Type: "Core",
              CustomFieldTypeColumnName: idField.CustomFieldTypeColumnName,
              Order: 0,
              label: `${objectTypeGroupName}: ${idField.CoreFieldLabel}`,
              value: `0_${idField.CoreFieldID}`,
            },
          ];
  }
};

const groupReport = (
  reportRows: GeneratedReportRow[],
  columns: ReportColumn[],
  groupByID: string,
): GroupedGeneratedReportResult => {
  const result: GroupedGeneratedReportResult = {
    tables: [],
  };
  const groupedRows = reportRows.reduce((output, row) => {
    const groupByField = columns.find((c) => c.value === groupByID);
    const groupByColumnIndex = groupByField
      ? row.columns.findIndex((c) => c.columnID === groupByField.ReportColumnID)
      : -1;
    const groupByColumn =
      groupByColumnIndex >= 0
        ? row.columns[groupByColumnIndex]
        : {
            span: 1,
            order: 0,
            columnID: "",
            fieldID: "",
            fieldType: "",
            fieldDataType: "",
            value: { text: "" },
            valueText: "",
          };
    // since we display dates without time component, we can consider any time on the same day equal, otherwise the table headings look as though they're duplicated
    const findTableIndex = groupByColumn
      ? output.tables.findIndex((t) =>
          groupByColumn.fieldDataType === "DATETIME" &&
          groupByColumn.valueText !== ""
            ? moment.utc(groupByColumn.valueText).isSame(t.groupValue, "day")
            : groupByColumn.valueText === t.groupValue,
        )
      : -1;

    if (groupByColumnIndex >= 0) {
      row.columns.splice(groupByColumnIndex, 1);
    }
    if (findTableIndex >= 0) {
      output.tables[findTableIndex].bodyRows.push(row);
      return output;
    }
    const headerRowColumns = columns
      .map((c) => ({
        span: 1,
        order: c.Order,
        sort: c.Sort,
        text: c.label || "",
        id: c.ID,
        reportColumnID: c.ReportColumnID,
      }))
      .filter((c) =>
        groupByField ? c.reportColumnID !== groupByField.ReportColumnID : true,
      )
      .sort((a, b) => a.order - b.order);

    const table = {
      heading: groupByColumn ? formatCellValue(groupByColumn) : "",
      groupValue: groupByColumn ? groupByColumn.valueText : "",
      headerRows: [
        {
          columns: headerRowColumns,
        },
      ],
      bodyRows: [row],
      footerRows: [],
    };
    output.tables.push(table);
    return output;
  }, result);

  return groupedRows;
};

const sortReport = (
  report: GroupedGeneratedReportResult,
  columns: ReportColumn[],
) => {
  const sortCols = columns
    .filter((c) => c.Sort)
    .sort((a, b) => a.Sort - b.Sort);
  const sortedReport = report;

  const compare = (type: string) => {
    if (
      type === "VARCHAR" ||
      type === "LONGVARCHAR" ||
      type === "URL" ||
      type === "DATETIME"
    ) {
      return (a: any, b: any) =>
        (a.valueText || "").localeCompare(b.valueText || "", "en", {
          numeric: true,
        });
    }
    if (type === "INT") {
      return (a: any, b: any) =>
        (a.valueText || Infinity) - (b.valueText || Infinity);
    }
    return () => 0;
  };

  // if you sort from the last in sort order to the front then any ties should've been resolved with the previous sort
  sortCols.reverse().forEach((col) => {
    sortedReport.tables = sortedReport.tables.map((t) => ({
      ...t,
      bodyRows: t.bodyRows.sort((a, b) => {
        const [aField, bField] = [
          a.columns.find((c) => c.columnID === col.ReportColumnID),
          b.columns.find((c) => c.columnID === col.ReportColumnID),
        ];
        if (aField && bField) {
          const compareFunc = compare(aField.fieldDataType);
          return compareFunc(aField, bField);
        }
        if (aField && !bField) {
          return -1;
        }
        if (!aField && bField) {
          return 1;
        }
        return 0;
      }),
    }));
  });

  return sortedReport;
};

// handle cases where one record's db rows have been split across the paginated data - these need to be combined where appropriate
const combineReportResults = (
  existingResults: GeneratedReportRow[],
  newResults: GeneratedReportRow[],
): GeneratedReportRow[] => {
  const combinedResults = existingResults.concat(newResults);
  const finalResults: GeneratedReportRow[] = [];
  // if the last row of the last dataset and the first row of the last dataset don't contain any duplication, we don't need to go through all the results
  if (isNewReportRow(existingResults, newResults[0])) {
    return combinedResults;
  }
  // otherwise, we have db rows split across the data fetch that need to be combined

  return combinedResults.reduce((final, row) => {
    // valid reasons for a new row in the report table
    if (isNewReportRow(final, row)) {
      final.push(row);
    } else {
      final[final.length - 1].columns = final[final.length - 1].columns.map(
        (col, index) => {
          // parents, responsible users, multi lists, uploaders
          // if both rows have values, combine
          if (
            Array.isArray(col.value) &&
            Array.isArray(row.columns[index].value)
          ) {
            const finalValues = col.value;
            const newValues = row.columns[index].value;
            if (Array.isArray(newValues)) {
              // make typescript happy... again
              newValues.forEach((val) => {
                if (!finalValues.some((finalVal) => finalVal.id === val.id)) {
                  finalValues.push(val);
                }
              });
              return {
                ...col,
                value: finalValues,
                valueText: `${col.valueText}, ${row.columns[index].valueText}`,
              };
            }
            return col;
          }
          // if newer value is an array (for multi value fields) or not empty (for single value fields), and there's no old value to combine with, we should replace old value
          if (
            Array.isArray(row.columns[index].value) ||
            (col.valueText === "" && row.columns[index].valueText !== "")
          ) {
            return {
              ...col,
              value: row.columns[index].value,
              valueText: row.columns[index].valueText,
            };
          }
          return col;
        },
      );
    }
    return final;
  }, finalResults);
};

// start a new report row for each: record, instance (responses, due date, responsible user etc), note
// plus for each different relationship level
const isNewReportRow = (records: GeneratedReportRow[], row: any) =>
  records.length === 0 ||
  records[records.length - 1].recordID !== row.recordID ||
  records[records.length - 1].parentID !== row.parentID ||
  records[records.length - 1].grandParentID !== row.grandParentID ||
  records[records.length - 1].childID !== row.childID ||
  records[records.length - 1].actionID !== row.actionID ||
  (records[records.length - 1].instanceID !== null &&
    row.instanceID !== null &&
    records[records.length - 1].instanceID !== row.instanceID) ||
  !arraysAreEqual(records[records.length - 1].noteIDs, row.noteIDs, false);

export {
  displayReport,
  findNotNull,
  formatCellValue,
  formatTableDataCell,
  displayFilters,
  groupReport,
  sortReport,
  combineReportResults,
  formatColumnFieldsFromDB,
};
