import {
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Icon,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { BubbleMenu, Editor } from "@tiptap/react";
import { RiDeleteBin6Fill } from "react-icons/ri";

import { DropdownIcon } from "../../../../constants/commonIcons";
import TableHeader from "@tiptap/extension-table-header";

type Props = {
  editor: Editor | null;
  isDisabled?: boolean;
  buttonVariant: string;
  buttonColorScheme: string;
};

const buttonDestruct = "red";
const textSize = "sm";

export default function RichTextTablePopover({
  editor,
  isDisabled,
  buttonVariant,
  buttonColorScheme,
}: Props) {
  if (!editor) return null;

  // const tableHeader = editor.getAttributes("th");
  // function bubbleMenuOffset(): [number, number] {
  //   if (tableHeader.hasFocus()) {
  //     const offset: [number, number] = [0, 95];
  //     return offset;
  //   }
  //   return [0, 0];
  // }

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        placement: "bottom",
        trigger: "manual",
      }}
      shouldShow={({ view }) => {
        if (!view.hasFocus()) return false;
        return editor.isActive("table");
      }}>
      <Box
        bg="white"
        borderRadius="sm"
        boxShadow="lg"
        p={1}
        alignItems="center">
        <Menu closeOnSelect={false}>
          <MenuButton
            h={8}
            as={Button}
            rightIcon={<Icon as={DropdownIcon} />}
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            _expanded={{ bg: "brand.50", textColor: "brand.600" }}
            fontSize={textSize}>
            Table options
          </MenuButton>
          <MenuList minW="fit-content" p={2}>
            <MenuItem
              onClick={() => editor.chain().focus().toggleHeaderRow().run()}
              isDisabled={isDisabled || !editor.can().toggleHeaderRow()}
              fontSize={textSize}>
              Header Row
            </MenuItem>
            <MenuItem
              onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
              isDisabled={isDisabled || !editor.can().toggleHeaderColumn()}
              fontSize={textSize}>
              Header Column
            </MenuItem>
          </MenuList>
        </Menu>

        <Menu closeOnSelect={false}>
          <MenuButton
            h={8}
            as={Button}
            rightIcon={<Icon as={DropdownIcon} />}
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            _expanded={{ bg: "brand.50", textColor: "brand.600" }}
            fontSize={textSize}>
            Cell options
          </MenuButton>
          <MenuList minW="fit-content" p={2}>
            <MenuItem
              onClick={() => editor.chain().focus().addColumnAfter().run()}
              isDisabled={isDisabled || !editor.can().addColumnAfter()}
              fontSize={textSize}>
              Insert column right
            </MenuItem>
            <MenuItem
              onClick={() => editor.chain().focus().addRowAfter().run()}
              isDisabled={isDisabled || !editor.can().addRowAfter()}
              fontSize={textSize}>
              Insert row below
            </MenuItem>
            <MenuItem
              onClick={() => editor.chain().focus().deleteColumn().run()}
              isDisabled={isDisabled || !editor.can().deleteColumn()}
              fontSize={textSize}>
              Delete column
            </MenuItem>
            <MenuItem
              onClick={() => editor.chain().focus().deleteRow().run()}
              isDisabled={isDisabled || !editor.can().deleteRow()}
              fontSize={textSize}>
              Delete row
            </MenuItem>
          </MenuList>
        </Menu>

        <Menu closeOnSelect={false}>
          <Tooltip label="Delete table">
            <MenuButton
              h={8}
              as={Button}
              variant={buttonVariant}
              colorScheme={buttonDestruct}
              onClick={() => editor.chain().focus().deleteTable().run()}
              isDisabled={isDisabled || !editor.can().deleteTable()}>
              <Icon as={RiDeleteBin6Fill} />
            </MenuButton>
          </Tooltip>
        </Menu>
      </Box>
    </BubbleMenu>
  );
}
