import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  chakra,
  Divider,
  Heading,
  LinkBox,
  LinkOverlay,
  Link as ChakraLink,
  Modal,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import CenteredAlert from "../../../../components/chakra/common/CenteredAlert";
import {
  useApiMutation,
  useApiQuery,
} from "../../../../utilities/apibelRequest";
import { getRecurrenceText } from "../../../../utilities/notificationRecurrenceHelpers";
import useToast from "../../../../utilities/useToast";
import NotificationSettingsModalContent, {
  NotificationState,
} from "./NotificationSettingsModalContents";

export default function NotificationsSettingsCard() {
  const { displayToast } = useToast();
  const editNotificationsModalState = useDisclosure({ defaultIsOpen: false });
  const queryClient = useQueryClient();
  const notificationSettingsMutation = useApiMutation(
    "userSettings/updateNotificationSettings",
  );
  const [editNotificationModalBase, setEditNotificationModalBase] =
    useState<NotificationState>({ type: "due", isEnabled: false });
  const tableMainTextColor = useColorModeValue("gray.900", "gray.100");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const notificationSettingsQuery = useApiQuery(
    "userSettings/notificationSettings",
    null,
  );

  const handleOpenModal = (type: "due" | "upcoming") => {
    if (!notificationSettingsQuery.isSuccess) {
      return;
    }
    const data =
      type === "due"
        ? notificationSettingsQuery.data.dueTaskNotifications
        : notificationSettingsQuery.data.upcomingTaskNotifications;
    if (data) {
      setEditNotificationModalBase({
        type,
        isEnabled: true,
        frequency: data.recurrenceID,
        sendOn: data.sendOn,
      });
    } else {
      setEditNotificationModalBase({
        type,
        isEnabled: false,
      });
    }
    editNotificationsModalState.onOpen();
  };

  const handleSubmit = async (data: NotificationState) => {
    setIsSubmitting(true);
    try {
      const res = await notificationSettingsMutation.mutateAsync({
        [data.type]: {
          isEnabled: data.isEnabled,
          frequency: data.isEnabled ? data.frequency : undefined,
          sendOn: data.isEnabled ? data.sendOn : undefined,
        },
      });
      queryClient.invalidateQueries(["userSettings", "notificationSettings"]);
      displayToast({
        status: "success",
        title: "Notification settings updated",
      });
      setIsSubmitting(false);
      return true;
    } catch (e) {
      displayToast({
        status: "error",
        title: "Failed to update notification settings",
      });
    }
    setIsSubmitting(false);
    return false;
  };

  const dueTaskText = notificationSettingsQuery.data?.dueTaskNotifications
    ? getRecurrenceText(
        notificationSettingsQuery.data.dueTaskNotifications.recurrenceID,
        notificationSettingsQuery.data.dueTaskNotifications.sendOn,
      )
    : null;
  const upcomingTaskText = notificationSettingsQuery.data
    ?.upcomingTaskNotifications
    ? getRecurrenceText(
        notificationSettingsQuery.data.upcomingTaskNotifications.recurrenceID,
        notificationSettingsQuery.data.upcomingTaskNotifications.sendOn,
      )
    : null;

  return (
    <>
      <Card w="full" variant="outline">
        <CardHeader>
          <Heading size="md">Notifications</Heading>
        </CardHeader>
        <CardBody>
          <VStack w="full" align="start" justify="start" spacing="4">
            {notificationSettingsQuery.isError ? (
              <CenteredAlert status="error" title="Something went wrong">
                Failed to load Notification settings
              </CenteredAlert>
            ) : (
              <>
                <VStack w="full" align="start">
                  <Heading size="sm">Task Notifications</Heading>
                  <Text>
                    Send a regular reminder of any due or overdue, plus any
                    upcoming (in the next 3 months) tasks assigned to you.
                  </Text>
                  <Divider />

                  {notificationSettingsQuery.isLoading ? (
                    <>
                      <Skeleton h="6" w="50%" />
                      <Skeleton h="8" w="40%" />
                    </>
                  ) : notificationSettingsQuery.isSuccess ? (
                    <>
                      {upcomingTaskText ? (
                        <Text>
                          Sending upcoming tasks{" "}
                          <chakra.span fontWeight="700" color="brand.700">
                            {upcomingTaskText[0]}
                          </chakra.span>{" "}
                          {upcomingTaskText[1]}{" "}
                          <chakra.span
                            as="span"
                            fontWeight="700"
                            color="brand.700">
                            {upcomingTaskText[2]}
                          </chakra.span>
                        </Text>
                      ) : (
                        <Text>Not sending upcoming tasks</Text>
                      )}
                      <Button
                        isDisabled={notificationSettingsQuery.isFetching}
                        size="sm"
                        variant="ghost"
                        
                        
                        onClick={() => handleOpenModal("upcoming")}>
                        Edit upcoming notifications
                      </Button>
                      <Divider />
                      {dueTaskText ? (
                        <Text>
                          Sending due/overdue tasks{" "}
                          <chakra.span fontWeight="700" color="brand.700">
                            {dueTaskText[0]}
                          </chakra.span>{" "}
                          {dueTaskText[1]}{" "}
                          <chakra.span
                            as="span"
                            fontWeight="700"
                            color="brand.700">
                            {dueTaskText[2]}
                          </chakra.span>
                        </Text>
                      ) : (
                        <Text>Not sending due/overdue tasks</Text>
                      )}
                      <Button
                        isDisabled={notificationSettingsQuery.isFetching}
                        size="sm"
                        variant="ghost"
                        
                        
                        onClick={() => handleOpenModal("due")}>
                        Edit due/overdue notifications
                      </Button>
                    </>
                  ) : null}
                  <Divider />
                </VStack>
                <VStack w="full" align="start">
                  <Heading size="sm">Scheduled Reports</Heading>
                  {notificationSettingsQuery.isLoading ? (
                    <>
                      <Skeleton w="60%" h="8" />
                      <Skeleton w="100%" h="8" />
                    </>
                  ) : notificationSettingsQuery.isSuccess ? (
                    <>
                      {notificationSettingsQuery.data.scheduledReports.length <=
                        0 && <Text>No scheduled reports set up.</Text>}
                      <Table size="sm" variant="data-table">
                        <Thead>
                          <Tr>
                            <Th>Report</Th>
                            <Th>Recurrence</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {notificationSettingsQuery.data.scheduledReports.map(
                            ({
                              notificationID,
                              reportID,
                              reportName,
                              reportReadableID,
                              recurrenceID,
                              sendOn,
                            }) => (
                              <Tr key={notificationID}>
                                <LinkBox
                                  _hover={{ textDecor: "underline" }}
                                  as={Td}
                                  color={tableMainTextColor}>
                                  <Badge variant="subtle" colorScheme="gray">
                                    {reportReadableID}
                                  </Badge>
                                  <LinkOverlay
                                    ps="2"
                                    as={Link}
                                    to={`/report/${reportID}`}>
                                    {reportName}
                                  </LinkOverlay>
                                </LinkBox>
                                <Td>
                                  {getRecurrenceText(recurrenceID, sendOn).join(
                                    " ",
                                  )}
                                </Td>
                              </Tr>
                            ),
                          )}
                        </Tbody>
                      </Table>
                      <Text>
                        You can set up report schedules in the{" "}
                        <ChakraLink
                          color="blue.600"
                          as={Link}
                          to="/report?display=reports">
                          reports module
                        </ChakraLink>
                        .
                      </Text>
                    </>
                  ) : null}
                </VStack>
              </>
            )}
          </VStack>
        </CardBody>
      </Card>
      <Modal
        isOpen={editNotificationsModalState.isOpen}
        onClose={editNotificationsModalState.onClose}
        closeOnOverlayClick={!isSubmitting}>
        <ModalOverlay />
        <NotificationSettingsModalContent
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          taskLifecycleType={editNotificationModalBase.type}
          onClose={editNotificationsModalState.onClose}
          currentState={editNotificationModalBase}
        />
      </Modal>
    </>
  );
}
