import {
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
  Icon,
  Button,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { RiTable2 } from "react-icons/ri";
import { useState } from "react";
import { DropdownIcon } from "../../../../constants/commonIcons";

type Props = {
  editor: Editor | null;

  iconSize: string;
  buttonColorScheme: string;
  buttonVariant: string;
};

// todo: loop over 0-10 in both column and row and then use that for the button on creating a table

export default function RichTextInsertTable({
  editor,
  iconSize,
  buttonColorScheme,
  buttonVariant,
}: Props) {
  const [hoveredCell, setHoveredCell] = useState<[number, number] | null>(null);

  // command for editor to create table dependent on where the mouse clicks (onClick)
  const handleBoxClick = (rows: number, cols: number) => {
    if (editor) {
      editor
        .chain()
        .focus()
        .insertTable({ rows, cols, withHeaderRow: true })
        .run();
    }
  };

  if (!editor) return null;

  return (
    <Menu size="sm">
      <Tooltip label="Insert Table">
        <MenuButton
          as={Button}
          rightIcon={<Icon as={DropdownIcon} />}
          variant={buttonVariant}
          colorScheme={buttonColorScheme}
          size="md"
          fontSize="md">
          <Icon size={iconSize} as={RiTable2} />
        </MenuButton>
      </Tooltip>

      <MenuList p={2}>
        <Grid templateColumns="repeat(6, 30px)" gap="0.5">
          {/* array loop using the .from to create a 6 x 6 of squares */}
          {Array.from({ length: 6 }).map((_, rowIndex) =>
            Array.from({ length: 6 }).map((__, colIndex) => {
              // determines if to highlight the cell based on where the mouse is located
              const highlightedValue =
                hoveredCell &&
                rowIndex <= hoveredCell[0] &&
                colIndex <= hoveredCell[1];

              return (
                <GridItem
                  width={30}
                  height={30}
                  border="1px solid"
                  cursor="pointer"
                  backgroundColor={highlightedValue ? "gray.200" : undefined}
                  key="test"
                  // sets the cell to the highlight value if mouse hovers over it
                  onMouseEnter={() => setHoveredCell([rowIndex, colIndex])}
                  // removes cell highlight once mouse moves away IF it is not part of the box displaying the created size table
                  onMouseLeave={() => setHoveredCell(null)}
                  // creates table in editor
                  onClick={() => handleBoxClick(rowIndex + 1, colIndex + 1)}
                />
              );
            }),
          )}
        </Grid>
      </MenuList>
    </Menu>
  );
}
