import { Box, HStack, Icon, IconButton, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RiCloseLine, RiInformationLine } from "react-icons/ri";

const info: {
  key: string;
  title: string;
  description: string;
}[] = [];

function checkKeyInLocalStorage(key: string) {
  return localStorage.getItem(key) === "true";
}

function setKeyInLocalStorage(key: string) {
  localStorage.setItem(key, "true");
}

export function InfoBar() {
  const [ready, setReady] = useState(false);
  const [infoToShow, setInfoToShow] = useState<typeof info>([]);

  useEffect(() => {
    if (!ready) {
      const filteredInfo = info.filter((i) => !checkKeyInLocalStorage(i.key));
      if (filteredInfo.length > 0) {
        setInfoToShow(filteredInfo);
        setReady(true);
      }
    }
  }, [ready]);

  const currentItem = infoToShow[0];

  const handleClickClose = () => {
    if (!currentItem) return;
    setKeyInLocalStorage(currentItem.key);
    setReady(false);
  };

  return ready && currentItem ? (
    <Box w="100%">
      <chakra-scope>
        <VStack spacing="0" color="black" bg="orange.300" padding={2}>
          <HStack align="center" justify="center">
            <Icon
              color="orange.800"
              fontSize="20"
              as={RiInformationLine}
              margin="0 8px"
            />
            <Text as="span" fontWeight="600">
              {currentItem.title}
            </Text>

            <Box ml="4">
              <IconButton
                colorScheme="gray"
                onClick={handleClickClose}
                aria-label="Close banner"
                size="lg"
                variant="link"
                _hover={{ color: "gray.800" }}
                icon={<RiCloseLine size="20" />}
              />
            </Box>
          </HStack>
          <Text fontSize="xs" color="gray.700">
            {currentItem.description}
          </Text>
        </VStack>
      </chakra-scope>
    </Box>
  ) : null;
}
