import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import {
  BrowserRouter as Router,
  useNavigate
} from "react-router-dom";

// Mantine styles
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";

import "./App.css";
import "./manualTiptap.css";

import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { config } from "./constants/config";
import CSSReset from "./screens/ChakraCSSReset";
import { ErrorBoundary } from "./screens/ErrorBoundary/ErrorBoundary";
import chakraTheme, { clerkStyle } from "./theme";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import useToast from "./utilities/useToast";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      cacheTime: 1000 * 10,
      retry: 0, // Disable retries - temp
      refetchOnWindowFocus: false,
    },
  },
});

// anything that exists over each page goes here
// managed auth, and rendering the correct routes
const App = () => {
  const { displayToast } = useToast();
  const navigate = useNavigate();

  // const handleSignOut = useCallback(() => {
  //   Auth.signOut()
  //     .then(() => navigate("/"))
  //     .catch((err) => console.log(err));
  // }, [navigate]);

  // useEffect(() => {
  //   const listener = (data: any) => {
  //     switch (data.payload.event) {
  //       case "tokenRefresh_failure":
  //         setBaseAppState({
  //           type: "loggedout",
  //           currentPageTitle: "Home",
  //         });
  //         // Auth.signOut().then(() => window.location.reload());
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  //   Hub.listen("auth", listener);

  //   return () => {
  //     Hub.remove("auth", listener);
  //   };
  // }, []);

  // Timeout stuff
  // const handleTimeout = useCallback(() => {
  //   window.localStorage.clear();
  //   handleSignOut();
  // }, [handleSignOut]);

  // useEffect(() => {
  //   if (baseAppState.type === "ready") {
  //     const timeout = baseAppState.app.accountInfo?.accountTimeout ?? null;
  //     if (timeout !== null) {
  //       const timer = new IdleTimer({
  //         timeout: timeout * 60 + 1,
  //         onTimeout: handleTimeout,
  //         onExpired: handleTimeout,
  //       });
  //       return () => timer.cleanUp();
  //     }
  //   }
  //   return () => {};
  // }, [baseAppState, handleTimeout]);

  // Our main routes either UnauthenticatedApp or AuthenticatedApp depending on if the user is logged in
  // UnauthenticatedApp just redirects to the login route

  return (
    <ClerkProvider
      appearance={clerkStyle}
      publishableKey={config.VITE_CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl="/"
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}>
      <SignedOut>
        <UnauthenticatedApp />
      </SignedOut>
      <SignedIn>
        <AuthenticatedApp />
      </SignedIn>
    </ClerkProvider>
  );

  return (
    <>
      {/* <AuthComponent
        baseAppState={baseAppState}
        updateBaseAppState={setBaseAppState}
      />
      <Routes>
        <Route
          path="/login"
          element={<LoginScreen baseAppState={baseAppState} />}
        />
        <Route
          path="*"
          element={
            <ProtectedRoutes
              setBaseAppState={setBaseAppState}
              baseAppState={baseAppState}
            />
          }
        />
      </Routes> */}
    </>
  );
};

const AppWithRouter: React.FC = () => (
  <ChakraProvider theme={chakraTheme} resetCSS={false}>
    <QueryClientProvider client={queryClient}>
      <CSSReset />
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </QueryClientProvider>
  </ChakraProvider>
);

export default AppWithRouter;
