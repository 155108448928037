import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Frown } from "react-feather";
import Bugsnag, { Event } from "@bugsnag/browser";

class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; url: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      url: "",
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentWillReceiveProps(nextProps: any) {
    // Used so when the navbar is used we try the request again
    this.setState({
      hasError: false,
      url: window.location.href,
    });
  }

  async componentDidCatch(error: any, info: any) {
    console.log("error", error);
    console.log("info", info);

    // Log error

    if (!window.location.href.includes("localhost")) {
      // put("error", errorObject, getToken);
      Bugsnag.notify(error, (event: Event) => {
        event.addMetadata("info", { info, isErrorBoundary: true });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Card style={{ marginTop: "20%", textAlign: "center" }}>
            <Row style={{ marginBottom: "20px" }}>
              <Col>
                <Frown />
              </Col>
            </Row>
            <Row>
              <Col>
                <h1>
                  Oops! We&apos;re sorry, it looks like something has gone wrong
                </h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h1>Our development team has been notified</h1>
              </Col>
            </Row>
          </Card>
        </Container>
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
