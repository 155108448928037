import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { useAppState } from "../../../../components/App/AppProvider";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import { formatDateOrIsoString } from "../../../../utilities/dateUtils";
import { EditManualOwnerModal } from "./EditManualOwnerModal";

type Props = {
  manualID: string;
};

export default function ManualDetailsCard({ manualID }: Props) {
  const { permissions } = useAppState().app;

  const updateOwnerModal = useDisclosure({ defaultIsOpen: false });
  const manualQuery = useApiQuery(
    "manual/byID",
    {
      manualID: manualID || "",
    },
    { enabled: !!manualID },
  );

  const isOwn =
    manualQuery.data?.responsibleUser.userID === permissions.INFO.userID;

  const canEdit =
    permissions.MANUAL.UPDATE.ALL || (permissions.MANUAL.UPDATE.SELF && isOwn);

  const handleOpenEditOwner = () => {
    updateOwnerModal.onOpen();
  };
  const handleCancelEditOwner = () => {
    updateOwnerModal.onClose();
  };

  return (
    <Box pb={6}>
      <Card variant="outline">
        <CardHeader>
          <Text>Manual Summary</Text>
        </CardHeader>
        <CardBody>
          {manualQuery.isSuccess && (
            <>
              <VStack
                align="left"
                pb={6}
                _focusWithin={{
                  ".hover-buttons": {
                    visibility: "visible",
                    opacity: 1,
                  },
                }}
                _hover={{
                  ".hover-buttons": {
                    visibility: "visible",
                    opacity: 1,
                  },
                }}>
                <HStack justify="space-between">
                  <Text fontSize="sm" color="gray.500">
                    Manual Owner:
                  </Text>
                  <Tooltip
                    label={
                      !canEdit
                        ? "You don't have permission to edit this"
                        : undefined
                    }>
                    <Button
                      size="sm"
                      variant="ghost"
                      visibility="hidden"
                      className="hover-buttons"
                      opacity={0}
                      isDisabled={!canEdit}
                      leftIcon={<RiPencilLine size={16} />}
                      onClick={handleOpenEditOwner}>
                      Edit
                    </Button>
                  </Tooltip>
                </HStack>
                <Text>
                  <Avatar
                    size="xs"
                    name={`${manualQuery.data.responsibleUser.firstName} ${manualQuery.data.responsibleUser.lastName}`}
                  />{" "}
                  {`${manualQuery.data.responsibleUser.firstName} ${manualQuery.data.responsibleUser.lastName}`}
                </Text>
              </VStack>

              <VStack align="left">
                <Text fontSize="sm" color="gray.500">
                  Last Updated:
                </Text>
                <Text>
                  {formatDateOrIsoString(
                    manualQuery.data.modifiedTs,
                    "datetime-short",
                  )}
                </Text>
              </VStack>
            </>
          )}
        </CardBody>
      </Card>
      {manualID && manualQuery.isSuccess && (
        <EditManualOwnerModal
          manualID={manualID}
          isOpen={updateOwnerModal.isOpen}
          onClose={handleCancelEditOwner}
          manualResponsibleUser={manualQuery.data.responsibleUser}
        />
      )}
    </Box>
  );
}
