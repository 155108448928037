import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import { RiDraggable, RiListCheck, RiSortDesc } from "react-icons/ri";
import { useQueryClient } from "@tanstack/react-query";
import { useAppState } from "../../../../components/App/AppProvider";
import { useApiMutation } from "../../../../utilities/apibelRequest";
import useToast from "../../../../utilities/useToast";

type Props = {
  items: {
    label: string;
    link: string;
    id: string;
  }[];
  manualDetails: {
    manualID: string;
    responsibleUser: string;
  };
  onClick: (index: number) => void;
  activeIndex: number;
};

type ReorderableItem = {
  label: string;
  link: string;
  sectionID: string;
  originalIndex: number;
  newIndex: number;
};

function findChangedPositions(
  originalItems: ReorderableItem[],
  newItems: ReorderableItem[],
) {
  const changes: ReorderableItem[] = [];

  const originalIndexMap = new Map<string, number>();
  originalItems.forEach((item, index) => {
    originalIndexMap.set(item.label, index);
  });

  newItems.forEach((item, newIndex) => {
    const originalIndex = originalIndexMap.get(item.label);

    if (originalIndex !== undefined && originalIndex !== newIndex) {
      changes.push({
        ...item,
        originalIndex,
        newIndex,
      });
    }
  });

  return changes;
}

export default function TableOfContents({
  items,
  onClick,
  activeIndex,
  manualDetails,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentItems, setCurrentItems] = useState<ReorderableItem[]>(
    items.map((item, index) => ({
      ...item,
      sectionID: item.id, // use `id` as `sectionID`
      originalIndex: index,
      newIndex: index,
    })),
  );

  const [originalItems, setOriginalItems] = useState(currentItems);
  const updateSectionOrder = useApiMutation("manual/updateManualSectionOrder");
  const queryClient = useQueryClient();
  const { permissions } = useAppState().app;
  const { displayToast } = useToast();
  const isOwn = manualDetails.responsibleUser === permissions.INFO.userID;

  // Check they can update the manuals sections
  const canEdit =
    permissions.MANUAL.UPDATE.ALL || (permissions.MANUAL.UPDATE.SELF && isOwn);

  useEffect(() => {
    setCurrentItems(
      items.map((item, index) => ({
        ...item,
        sectionID: item.id,
        originalIndex: index,
        newIndex: index,
      })),
    );
  }, [items]);

  const handleEditClick = () => {
    setOriginalItems(currentItems);
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setCurrentItems(originalItems);
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    try {
      const reordered = findChangedPositions(originalItems, currentItems);
      await updateSectionOrder.mutateAsync({
        sections: reordered,
        manualID: manualDetails.manualID,
      });
      setIsEditing(false);
      queryClient.invalidateQueries(["manual"]);
      displayToast({
        title: "Accepted",
        description: "Sections reordered successfully",
        status: "success",
      });
    } catch (error) {
      setCurrentItems(originalItems);
      setIsEditing(false);
      displayToast({
        title: "Error",
        description: "Failed to reorder sections. Please try again.",
        status: "error",
      });
      console.error("Error updating section order:", error);
    }
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(currentItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setCurrentItems(
      reorderedItems.map((item, index) => ({ ...item, newIndex: index })),
    );
  };

  const linkHeight = 36;
  const indicatorSize = 10;
  const indicatorOffset = (linkHeight - indicatorSize) / 2;

  const renderedItems = currentItems.map((item, index) => (
    <Draggable
      key={item.label}
      draggableId={item.label}
      isDragDisabled={!isEditing}
      index={index}>
      {(provided) => (
        <Box
          as="a"
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            onClick(index);
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          display="flex"
          textDecor="none"
          fontSize="sm"
          height={9}
          lineHeight={9}
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
          borderLeftWidth="2px"
          borderLeftStyle="solid"
          _hover={{
            bg: "gray.50",
            textDecor: "none",
          }}
          cursor={isEditing ? "move" : "pointer"}
          fontWeight={activeIndex === index ? "500" : undefined}
          color={activeIndex === index ? "brand.800" : "gray.800"}
          px={4}
          noOfLines={1}>
          {isEditing ? <Icon as={RiDraggable} size="md" /> : null}
          {item.label}
        </Box>
      )}
    </Draggable>
  ));

  return (
    <Box
      _focusWithin={{
        ".hover-button": {
          visibility: "visible",
          opacity: 1,
        },
      }}
      _hover={{
        ".hover-button": {
          visibility: "visible",
          opacity: 1,
        },
      }}
      pos="relative"
      height="calc(100vh - 120px)"
      overflowY="auto"
      pl="1">
      <HStack align="center" marginBottom="4" w="100%">
        <RiListCheck style={{ width: 16, height: 16 }} />
        <Text margin="0">Sections</Text>
        <HStack w="100%" spacing={2} pr="4" align="center" justify="end">
          {isEditing ? (
            <>
              <Button size="sm" colorScheme="gray" onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button size="sm" onClick={handleSaveClick}>
                Save
              </Button>
            </>
          ) : (
            <Box visibility="hidden" opacity={0} className="hover-button">
              <Tooltip
                label={
                  canEdit
                    ? "Reorder Sections"
                    : "You do not have permission to reorder this manual"
                }
                aria-label="Reorder Sections">
                <IconButton
                  aria-label="edit"
                  variant="ghost"
                  size="sm"
                  isDisabled={!canEdit}
                  onClick={handleEditClick}
                  icon={<RiSortDesc />}
                />
              </Tooltip>
            </Box>
          )}
        </HStack>
      </HStack>
      {items.length >= 1 ? (
        <Box
          height="calc(100vh - 420px)"
          overflowY="auto"
          pos="relative"
          pl="2">
          <Box
            transition="transform 150ms ease"
            borderWidth="2px"
            background="white"
            borderColor="brand.800"
            borderStyle="solid"
            height={`${indicatorSize}px`}
            width={`${indicatorSize}px`}
            borderRadius={`${indicatorSize}px`}
            pos="absolute"
            left="-1px"
            display={activeIndex < 0 ? "none" : undefined}
            transform={`translate(50%, ${
              activeIndex * linkHeight + indicatorOffset
            }px)`}
          />
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {renderedItems}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      ) : (
        <div>
          <Text color="gray.600">Manual is empty...</Text>
        </div>
      )}
    </Box>
  );
}
