import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StackDivider,
  useDisclosure,
} from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import {
  RiAlignLeft,
  RiBold,
  RiEdit2Line,
  RiHeading,
  RiInformationLine,
  RiItalic,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
  RiStrikethrough,
  RiSubscript,
  RiSuperscript,
  RiUnderline,
} from "react-icons/ri";
import { DropdownIcon } from "../../../../constants/commonIcons";
import { RichTextButton } from "./RichTextButton";
import RichTextShortcutsModal from "./RichTextShortcutsModal";
import RichTextColorPicker from "./RichTextColorPicker";
import RichTextInsertTable from "./RichTextInsertTable";
import RichTextTablePopover from "./RichTextTablePopover";

type Props = {
  editor: Editor | null;
  isDisabled?: boolean;
};

const iconSize = "18";
const buttonSize = "md";
const buttonColorScheme = "gray";
const buttonVariant = "ghost";

const headingLevelToLabelAndIcon = (
  level: number | undefined,
): [React.ReactElement, string] => {
  if (level === undefined)
    return [<RiParagraph size={iconSize} />, "Normal text"];
  return [<RiHeading size={iconSize} />, `Heading ${level}`];
};

export function RichTextToolbar({ editor, isDisabled }: Props) {
  const openShortcutsModal = useDisclosure({ defaultIsOpen: false });

  if (!editor) return null;

  const [textSizeIcon, textSizeLabel] = headingLevelToLabelAndIcon(
    editor.getAttributes("heading")?.level,
  );

  const handleOpenShortcutsModal = () => {
    openShortcutsModal.onOpen();
  };

  const handleCloseShortcutsModal = () => {
    openShortcutsModal.onClose();
  };

  return (
    <chakra-scope>
      <Box pl="2" m="auto" maxW="container.lg">
        <HStack
          py="0.5"
          gap="2"
          divider={<StackDivider mt="3px !important" mb="3px !important" />}>
          <Menu closeOnSelect={false} size="sm">
            {({ onClose }) => (
              <>
                <MenuButton
                  as={Button}
                  minW="40"
                  leftIcon={textSizeIcon}
                  rightIcon={<Icon as={DropdownIcon} />}
                  variant={buttonVariant}
                  colorScheme={buttonColorScheme}
                  size="sm"
                  fontSize="sm">
                  {textSizeLabel}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    bg={editor.isActive("paragraph") ? "brand.50" : undefined}
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                      editor.chain().focus().setParagraph().run();
                      setTimeout(() => editor.commands.focus(), 0);
                    }}>
                    Normal text
                  </MenuItem>
                  <MenuItem
                    bg={
                      editor.isActive("heading", { level: 1 })
                        ? "brand.50"
                        : undefined
                    }
                    onClick={() => {
                      onClose();
                      editor
                        .chain()
                        .focus()
                        .setHeading({ level: 1 })
                        .focus()
                        .run();
                      setTimeout(() => editor.commands.focus(), 0);
                    }}>
                    Heading 1
                  </MenuItem>
                  <MenuItem
                    bg={
                      editor.isActive("heading", { level: 2 })
                        ? "brand.50"
                        : undefined
                    }
                    onClick={() => {
                      onClose();
                      editor
                        .chain()
                        .focus()
                        .setHeading({ level: 2 })
                        .focus()
                        .run();
                      setTimeout(() => editor.commands.focus(), 0);
                    }}>
                    Heading 2
                  </MenuItem>
                  <MenuItem
                    bg={
                      editor.isActive("heading", { level: 3 })
                        ? "brand.50"
                        : undefined
                    }
                    onClick={() => {
                      onClose();
                      editor
                        .chain()
                        .focus()
                        .setHeading({ level: 3 })
                        .focus()
                        .run();
                      setTimeout(() => editor.commands.focus(), 0);
                    }}>
                    Heading 3
                  </MenuItem>
                  <MenuItem
                    bg={
                      editor.isActive("heading", { level: 4 })
                        ? "brand.50"
                        : undefined
                    }
                    onClick={() => {
                      onClose();
                      editor
                        .chain()
                        .focus()
                        .setHeading({ level: 4 })
                        .focus()
                        .run();
                      setTimeout(() => editor.commands.focus(), 0);
                    }}>
                    Heading 4
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>

          <ButtonGroup
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            size={buttonSize}
            spacing="0">
            <RichTextButton
              isDisabled={
                isDisabled || !editor.can().chain().focus().toggleBold().run()
              }
              onClick={() => editor.chain().focus().toggleBold().run()}
              isActive={editor.isActive("bold")}
              label="Bold">
              <RiBold size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled || !editor.can().chain().focus().toggleItalic().run()
              }
              onClick={() => editor.chain().focus().toggleItalic().run()}
              isActive={editor.isActive("italic")}
              label="Italic">
              <RiItalic size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().toggleUnderline().run()
              }
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              isActive={editor.isActive("underline")}
              label="Underline">
              <RiUnderline size={iconSize} />
            </RichTextButton>
            {/* 
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor
                  .can()
                  .chain()
                  .focus()
                  .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                  .run()
              }
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                  .run()
              }
              label="Table">
              <RiTable2 size={iconSize} />
            </RichTextButton> */}
            <RichTextInsertTable
              editor={editor}
              rows={2}
              columns={2}
              iconSize={iconSize}
              buttonColorScheme={buttonColorScheme}
              buttonVariant={buttonVariant}
            />
          </ButtonGroup>

          <ButtonGroup
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            size={buttonSize}
            spacing="0">
            <RichTextButton
              isDisabled={
                isDisabled || !editor.can().chain().focus().toggleStrike().run()
              }
              onClick={() => editor.chain().focus().toggleStrike().run()}
              label="Strike">
              <RiStrikethrough size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().toggleHighlight().run()
              }
              onClick={() => editor.chain().focus().toggleHighlight().run()}
              label="Highlight">
              <RiEdit2Line size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().toggleSubscript().run()
              }
              onClick={() => editor.chain().focus().toggleSubscript().run()}
              isActive={editor.isActive("subscript")}
              label="Subscript">
              <RiSubscript size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().toggleSuperscript().run()
              }
              onClick={() => editor.chain().focus().toggleSuperscript().run()}
              isActive={editor.isActive("superscript")}
              label="Superscript">
              <RiSuperscript size={iconSize} />
            </RichTextButton>
          </ButtonGroup>

          <ButtonGroup
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            size={buttonSize}
            spacing="0">
            <RichTextColorPicker
              editor={editor}
              iconSize={iconSize}
              buttonColorScheme={buttonColorScheme}
              buttonVariant={buttonVariant}
            />
          </ButtonGroup>

          <ButtonGroup
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            size={buttonSize}
            spacing="0">
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().unsetTextAlign().run()
              }
              isActive={editor.isActive({ textAlign: "left" })}
              onClick={() => editor.chain().focus().unsetTextAlign().run()}
              label="Align Left">
              <RiAlignLeft size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().setTextAlign("center").run()
              }
              isActive={editor.isActive({ textAlign: "center" })}
              onClick={() =>
                editor.chain().focus().setTextAlign("center").run()
              }
              label="Align Center">
              <RiAlignLeft size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().setTextAlign("right").run()
              }
              isActive={editor.isActive({ textAlign: "right" })}
              onClick={() => editor.chain().focus().setTextAlign("right").run()}
              label="Align Right">
              <RiAlignLeft size={iconSize} />
            </RichTextButton>
          </ButtonGroup>

          <ButtonGroup
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            size={buttonSize}
            spacing="0">
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().toggleBulletList().run()
              }
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive("bulletList")}
              label="Bullet List">
              <RiListUnordered size={iconSize} />
            </RichTextButton>
            <RichTextButton
              isDisabled={
                isDisabled ||
                !editor.can().chain().focus().toggleOrderedList().run()
              }
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              isActive={editor.isActive("orderedList")}
              label="Ordered List">
              <RiListOrdered size={iconSize} />
            </RichTextButton>
          </ButtonGroup>

          <ButtonGroup
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            size={buttonSize}>
            <RichTextButton
              onClick={handleOpenShortcutsModal}
              label="Keyboard Shortcuts">
              <RiInformationLine size={iconSize} />
            </RichTextButton>
          </ButtonGroup>
        </HStack>
      </Box>
      <RichTextTablePopover
        editor={editor}
        buttonVariant={buttonVariant}
        buttonColorScheme={buttonColorScheme}
      />
      <RichTextShortcutsModal
        isOpen={openShortcutsModal.isOpen}
        onClose={handleCloseShortcutsModal}
      />
    </chakra-scope>
  );
}
