import {
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Icon,
  Button,
} from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { RiCheckLine, RiPaletteLine } from "react-icons/ri";
import { DropdownIcon } from "../../../../constants/commonIcons";

type Props = {
  editor: Editor | null;
  iconSize: string;
  buttonColorScheme: string;
  buttonVariant: string;
};

const colorBorder = "md";
const colorWidth = "10";
const colorHeight = "6";

export default function RichTextColorPicker({
  editor,
  iconSize,
  buttonColorScheme,
  buttonVariant,
}: Props) {
  if (!editor) return null;

  const textColor = editor.getAttributes("textStyle").color;

  const selectedColor = `3px solid ${textColor ?? "black"}`;

  return (
    <Menu size="sm">
      <Tooltip label="Text colour">
        <MenuButton
          as={Button}
          rightIcon={<Icon as={DropdownIcon} />}
          variant={buttonVariant}
          colorScheme={buttonColorScheme}
          size="sm"
          fontSize="md">
          <Icon
            borderBottom={selectedColor}
            size={iconSize}
            as={RiPaletteLine}
          />
        </MenuButton>
      </Tooltip>
      <MenuList p={2}>
        <Grid templateColumns="repeat(6, 2fr)" gap={2}>
          <GridItem width={colorWidth}>
            <Tooltip label="Black">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="black"
                // icon={
                //   editor.isActive("textStyle", { color: "initial" }) ? (
                //     <RiCheckLine color="white" size="16" />
                //   ) : undefined
                // }
                // ^ not a fucking clue.
                onClick={() => editor.chain().focus().unsetColor().run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Blue">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="blue.600"
                icon={
                  editor.isActive("textStyle", { color: "#2b6cb0" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#2b6cb0").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Green">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="green.600"
                icon={
                  editor.isActive("textStyle", { color: "#2F855A" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#2F855A").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Orange">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="orange.500"
                icon={
                  editor.isActive("textStyle", { color: "#DD6B20" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#DD6B20").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Red">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="red.600"
                icon={
                  editor.isActive("textStyle", { color: "#C53030" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#C53030").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Purple">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="purple.600"
                icon={
                  editor.isActive("textStyle", { color: "#6B46C1" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#6B46C1").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Gray">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="gray.500"
                icon={
                  editor.isActive("textStyle", { color: "#718096" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#718096").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Light Blue">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="blue.300"
                icon={
                  editor.isActive("textStyle", { color: "#63b3ed" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#63b3ed").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Light Green">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="green.400"
                icon={
                  editor.isActive("textStyle", { color: "#48BB78" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#48BB78").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Yellow">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="yellow.400"
                icon={
                  editor.isActive("textStyle", { color: "#ECC94B" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#ECC94B").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Pink">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="pink.400"
                icon={
                  editor.isActive("textStyle", { color: "#ED64A6" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#ED64A6").run()}
              />
            </Tooltip>
          </GridItem>
          <GridItem width={colorWidth}>
            <Tooltip label="Light Purple">
              <MenuItem
                h={colorHeight}
                borderRadius={colorBorder}
                bg="purple.300"
                icon={
                  editor.isActive("textStyle", { color: "#B794F4" }) ? (
                    <RiCheckLine color="white" size="16" />
                  ) : undefined
                }
                onClick={() => editor.chain().focus().setColor("#B794F4").run()}
              />
            </Tooltip>
          </GridItem>
        </Grid>
      </MenuList>
    </Menu>
  );
}
