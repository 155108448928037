import {
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import { useRef } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function RichTextShortcutModal({ isOpen, onClose }: Props) {
  const initialFocusRef = useRef(null);

  const kbdColor = "black";

  // header values
  const headerSize = "lg";
  const headerWeight = "semibold";

  // table values
  const tVariant = "simple";
  const tScheme = "gray";
  const tSize = "md";
  const thSize = "xs";
  const tdFont = "sm";

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="2xl">
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader fontSize="2xl">Shortcuts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="left" pt={4}>
              <Text fontSize={headerSize} fontWeight={headerWeight}>
                Essentials
              </Text>
              <TableContainer py={4}>
                <Table variant={tVariant} colorScheme={tScheme} size={tSize}>
                  <Thead>
                    <Tr>
                      <Th fontSize={thSize}>Command</Th>
                      <Th fontSize={thSize}>Windows/Linux</Th>
                      <Th fontSize={thSize}>macOS</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td fontSize={tdFont}>Copy</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>C</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>C</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Cut</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>X</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>X</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Paste</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>V</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>V</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Undo</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>Z</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>Z</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Redo</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>Z</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>Z</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Select All</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>A</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>A</Kbd>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <Spacer />
              <Text fontSize={headerSize} fontWeight={headerWeight}>
                Text Formatting
              </Text>

              <TableContainer py={4}>
                <Table variant={tVariant} colorScheme={tScheme} size={tSize}>
                  <Thead>
                    <Tr>
                      <Th fontSize={thSize}>Command</Th>
                      <Th fontSize={thSize}>Windows/Linux</Th>
                      <Th fontSize={thSize}>macOS</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td fontSize={tdFont}>Bold</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>B</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>B</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Italic</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>I</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>I</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Underline</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>U</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>U</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Strikethrough</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>S</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>S</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Highlight</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>H</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>H</Kbd>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <Spacer />
              <Text fontSize={headerSize} fontWeight={headerWeight}>
                Paragraph Formatting
              </Text>

              <TableContainer py={4}>
                <Table variant={tVariant} colorScheme={tScheme} size={tSize}>
                  <Thead>
                    <Tr>
                      <Th fontSize={thSize}>Command</Th>
                      <Th fontSize={thSize}>Windows/Linux</Th>
                      <Th fontSize={thSize}>macOS</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td fontSize={tdFont}>Heading Style (Normal Text)</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>alt</Kbd> +{" "}
                        <Kbd color={kbdColor}>0</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>⌥</Kbd> +{" "}
                        <Kbd color={kbdColor}>0</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Heading Style (Heading 1)</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>alt</Kbd> +{" "}
                        <Kbd color={kbdColor}>1</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>⌥</Kbd> +{" "}
                        <Kbd color={kbdColor}>1</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Heading Style (Heading 2)</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>alt</Kbd> +{" "}
                        <Kbd color={kbdColor}>2</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>⌥</Kbd> +{" "}
                        <Kbd color={kbdColor}>2</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Heading Style (Heading 3)</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>alt</Kbd> +{" "}
                        <Kbd color={kbdColor}>3</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>⌥</Kbd> +{" "}
                        <Kbd color={kbdColor}>3</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Heading Style (Heading 4)</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>alt</Kbd> +{" "}
                        <Kbd color={kbdColor}>4</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>⌥</Kbd> +{" "}
                        <Kbd color={kbdColor}>4</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Ordered List</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>7</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>7</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Bullet List</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>8</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>8</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Left Align</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>L</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>L</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Centre Align</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>E</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>E</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Right Align</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>R</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>shift</Kbd> +{" "}
                        <Kbd color={kbdColor}>R</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Subscript</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>,</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>,</Kbd>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontSize={tdFont}>Superscript</Td>
                      <Td>
                        <Kbd color={kbdColor}>cmd</Kbd> +{" "}
                        <Kbd color={kbdColor}>.</Kbd>
                      </Td>
                      <Td>
                        <Kbd color={kbdColor}>⌘</Kbd> +{" "}
                        <Kbd color={kbdColor}>.</Kbd>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
