import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";

type Props = {
  title: string;
  isManualSection: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export function ConfirmDeleteModal({
  isOpen,
  onClose,
  onDelete,
  title,
  isManualSection,
}: Props) {
  const initialFocusRef = useRef(null);
  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>
            Delete {isManualSection ? " Section" : " Manual"}?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete the manual
              {isManualSection ? " section" : ""} &quot;
              {title}&quot;?
            </Text>
            <Text>
              All records will be unlinked, and the contents of this{" "}
              {isManualSection ? " section " : " manual "}
              will be lost.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                ref={initialFocusRef}
                onClick={onClose}
                colorScheme="gray">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => onDelete()}>
                Delete
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
